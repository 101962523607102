.portfolio {
    overflow-x: auto; /* Change from scroll to auto */
    overscroll-behavior-inline: contain;
    margin: 0 0 120px 0;
    scroll-snap-type: inline mandatory;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.portfolio::-webkit-scrollbar {
    display: none;
    width: 0px;
}
#portfolio__title {
    color: #000;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    margin: 120px 0 50px 0;
}
#portfolio__title > span {
    color: #10316B;
}
.portolio__slider {
    width: 150vw;
    display: flex;
    gap: 15px;
}
.portfolio__slide {
    color: white;
    font-weight: 900;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-self: center;
    scroll-snap-align: start;
}
.portfolio__slide img {
    width: 500px;
    max-width: 500px;
    height: 500px;
    object-fit: cover;
    border-radius: 30px;
}

@media (max-width: 800px) {
    .portfolio {
        padding-left: 1rem;
        margin-left: 5% !important;
    }
    #portfolio__title {
        text-align: center;
    }
    .portfolio__slide img {
        aspect-ratio: 3/4;
        max-width: 300px;
        height: 500px;
    }
}