nav {
    background-color: #fff;
    position: fixed;
    width: 100vw;
    height: auto;
    padding: 16px 0;
    z-index: 999;
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
}
nav > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav__logo img {
    height: 30px;
}
.nav__list {
    display: flex;
    justify-content: space-between;
    gap: 32px;
}
.nav__list li {
    cursor: pointer;
    color: var(--Black, #000);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    transition: 0.3s;
}
.nav__list li:hover {
    font-weight: 600;
}
.nav__button {
    border-radius: 30px;
    background: #10316B;
    color: var(--White, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: 0.3s;
}
.nav__button:hover {
    background-color: #000054;
}
.nav--down {
    translate: 0 -200%;
}

/* Mobile menu */
.mobile-only {
    display: none;
}
.mobile-only img {
    color: #10316B;
    height: 26px;
}
.mobile-menu {
    background-color: white;
    transition: 0.3s;
}
.mobile-menu > ul {
    width: 90%;
    margin: 50px auto 20px auto;
}
.mobile-menu > ul > li {
    line-height: 3;
    border-top: 1px solid #b9b9b9;
}
.mobile-menu > ul > li > a {
    width: 100%;
    transition: 0.1s;
}
.mobile-menu > ul > li > a:active {
    font-weight: bold;
}

/* Responsive design */
@media (max-width: 800px) {
    .mobile-only {
        display: block;
    }

    .nav__list, .nav__button {
        display: none;
    }
}