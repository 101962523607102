.reviews {
    position: relative;
    overflow-x: hidden;
    padding: 32px 0;
}

.reviews h4 {
    text-align: center;
    margin-bottom: 60px;
    color: #000;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
}

.reviews h4 > span {
    color: #10316B;
    font-weight: 900;
}

.reviews__content {
    display: flex;
    gap: 80px;
}
.reviews__content > * {
    width: 50%;
}

.reviews__content__elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.reviews__stars {
    display: flex;
    margin-bottom: 32px;
}

.reviews__content p {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
}

.reviews img {
    position: relative;
    z-index: 200;
    max-height: 650px;
    object-fit: cover;
    border-radius: 50px;
}

.decorCircle1 {
    width: 452px;
    height: 452px;
    border-radius: 452px;
    background: rgba(1, 1, 151, 0.35);
    filter: blur(125px);
    position: absolute;
    bottom: 0;
    left: 0;
}

.decorCircle2 {
    width: 853px;
    height: 853px;
    border-radius: 853px;
    background: rgba(1, 1, 62, 0.6);
    filter: blur(100px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

@media (max-width: 800px) {
    .reviews__content {
        flex-direction: column;
        align-items: center;
    }
    .reviews__content p {
        font-size: 18px;
    }
    .reviews__stars {
        margin-bottom: 15px;
    }
    .reviews__content > * {
        width: 90%;
    }
    .decorCircle2 {
        background: rgba(1, 1, 62, 0.3);
    }
}